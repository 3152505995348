/* eslint-disable react/no-unstable-nested-components */
import { Checkbox, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLoading } from '../../../../../components/Common/StyledLoading';
import StyledModal from '../../../../../components/Common/StyledModal';
import StyledSelect from '../../../../../components/Common/StyledSelect';
import { QuotesPage } from '../../../../QuotesPage';
import { SkuComponentsModal } from './Modal';
import * as S from './styles';
import { Component, SkuComponentsProps } from './types';
import { useSkuCompoents } from './useSkuComponents';

const SkuComponentsFunction = ({
  skus,
  period,
  level,
  cm,
  isFirstRendering,
}: SkuComponentsProps) => {
  const { t } = useTranslation();
  const {
    columns,
    datareceived,
    params,
    modalData,
    modalQuotesVisibility,
    categories,
    setModalQuotesVisibility,
    handleFetchCategories,
    setParams,
    handleGetSkusString,
    setModalData,
    fetchSkuComponents,
    handleCalculateAmountSkuByCm,
    isLoadingDataTable,
  } = useSkuCompoents();

  useEffect(() => {
    if (!isFirstRendering) {
      fetchSkuComponents(period, level, cm, skus);
      handleFetchCategories(skus, period, level, cm);
    }
  }, [params, skus, period]);
  if (skus?.length) {
    return (
      <>
        <S.SkuComponentsContainer>
          <S.SubCardHeader style={{ justifyContent: 'space-between' }}>
            <S.SubCardHeaderText>SKU Components</S.SubCardHeaderText>
            <Row style={{ gap: '1rem', paddingRight: '1rem' }}>
              <StyledSelect
                styles={{ minWidth: '8rem' }}
                placeholder={t('pages.finance.dashboard.skuComponents.table.labels.category')}
                showSearch
                options={categories}
                value={params.category}
                onChange={(value: string) => setParams({ ...params, category: value })}
              />
              <Checkbox
                checked={params.withGap}
                style={{ alignItems: 'center' }}
                onChange={() => setParams({ ...params, withGap: !params.withGap })}
              >
                W/Gap
              </Checkbox>
            </Row>
          </S.SubCardHeader>
          <S.BodyContainer>
            {isLoadingDataTable ? (
              <StyledLoading height={17} />
            ) : (
              <S.TablesContainer>
                {skus &&
                  skus.map((sku, index) => {
                    return (
                      <S.TableContainer key={sku.id}>
                        <S.FixedHeader>
                          <S.TableHeader>
                            <S.SkuHeaderText>{sku.sku}</S.SkuHeaderText>
                          </S.TableHeader>
                          <S.HighlightDivSkuColor color={sku.color} />
                        </S.FixedHeader>
                        <S.SkuComponentsTable
                          columns={columns}
                          bordered={false}
                          size="small"
                          dataSource={datareceived?.[index]?.components}
                          rowKey="component"
                          pagination={false}
                          scroll={{ x: 'max-content', y: 'auto' }}
                        />
                      </S.TableContainer>
                    );
                  })}
              </S.TablesContainer>
            )}
          </S.BodyContainer>
          <S.LegendContainer>
            <S.Legend>
              <S.LegendPrice type="HIGHER" />
              <span>{t('pages.finance.dashboard.skuComponents.table.labels.higherPrice')}</span>
            </S.Legend>
            <S.Legend>
              <S.LegendPrice type="LOWER" />
              <span>{t('pages.finance.dashboard.skuComponents.table.labels.lowerPrice')}</span>
            </S.Legend>
          </S.LegendContainer>
          {/* <S.SeeQuotesButton
            variant="primary"
            onClick={() => {
              setModalQuotesVisibility(true);
            }}
          >
            <EyeOutlined />
            {t('pages.finance.dashboard.skuComponents.table.labels.seeQuotes')}
          </S.SeeQuotesButton> */}
        </S.SkuComponentsContainer>
        {/* <iframe src={RoutesPath.quotes} title="Quotes" />; */}
        <SkuComponentsModal
          open={!!modalData?.name}
          onClose={() => setModalData({} as Component)}
          data={modalData}
        />
        {modalQuotesVisibility && (
          <StyledModal
            title="Quotes"
            onClose={() => {
              setModalQuotesVisibility(false);
            }}
            width="50rem"
            footer={null}
            open={modalQuotesVisibility}
            body={
              <div>
                <QuotesPage
                  financeLevel={level}
                  financeSkus={handleGetSkusString(skus)}
                  financeDate={period}
                  financeCM={handleCalculateAmountSkuByCm(skus)}
                  hideHeaders
                />
              </div>
            }
          />
        )}
      </>
    );
  }
  return null;
};

export const SkuComponents = React.memo(SkuComponentsFunction);
