import { HistoryOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Popconfirm, Row, Space, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import {
  clearRequestParams,
  debounce,
  getFilterURL,
  getSortOrder,
  getURI,
  hasPropertyWithHeader,
  isFobOrBr,
  oneAlert,
  removeEmptyFilter,
} from '../../helpers/utils';
import { api } from '../../services/api';

import cm from '../../assets/CM.png';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { ComponentsTypeSelect } from '../../components/forms/ComponentsTypeSelect';
import { LocalSelect } from '../../components/forms/LocalSelect';
import StyledIndicationTag from '../../components/IndicationTag';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { formatDataToSelect } from '../../helpers/nUtils';

const cmOptions = [
  { value: 'Foxconn', label: 'Foxconn' },
  { value: 'Compal', label: 'Compal' },
];
const productOptions = [
  { value: 'Desktop', label: 'Desktop' },
  { value: 'Notebook', label: 'Notebook' },
];

const sourceOptions = [
  { value: '', label: 'Show All' },
  { value: 'FOB', label: 'FOB' },
  { value: 'BR', label: 'BR' },
];

const initialParams = {
  cm: 'Foxconn',
  product: 'Notebook',
  order: '',
  orderBy: '',
  page: 0,
  limit: 25,
  subComponent: '',
  leadTime: '',
};

export function useComponents() {
  const call = 0;
  const { t } = useTranslation();

  const rangeDays = [
    { value: '0_0', label: `0 ${t('common.days')}` },
    { value: '1_15', label: `${t('common.upTo')} 15 ${t('common.days')}` },
    { value: '16_30', label: `16-30 ${t('common.days')}` },
    { value: '31_60', label: `31-60 ${t('common.days')}` },
    { value: '61_90', label: `61-90 ${t('common.days')}` },
    { value: '91_-', label: `91+ ${t('common.days')}` },
    { value: 'None', label: `${t('common.none')}` },
  ];

  const [form] = Form.useForm();
  const [isNewItem, setIsNewItem] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState({
    content: false,
    category: false,
    spec: false,
    partNumber: false,
    description: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [searchValue, setSearchValue] = useState('');
  const [expandedRowKeys, setExpandedRowKeys] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [specOptions, setSpecOptions] = useState([]);
  const [partnumberOptions, setPartNumberOptions] = useState([]);
  const [descriptionOptions, setdescriptionOptions] = useState([]);
  const [sourcOptions, setSourceOptions] = useState([]);
  const [refetchData, setRefetchData] = useState({ spec: [], partType: [], partNumber: [] });
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: '',
    totalElements: 0,
  });
  const [firstRender, setFirstRender] = useState(true);

  function getQueryParams() {
    const allParams = { ...params };

    if (!params.spec?.length) {
      allParams.spec = null;
    } else {
      allParams.spec = btoa(params.spec);
    }

    if (!params.partType?.length) allParams.partType = null;
    if (!params.partNumber?.length) allParams.partNumber = null;

    const queryParams = getFilterURL(removeEmptyFilter(clearRequestParams(allParams)));

    return queryParams;
  }

  const resetControls = (expandeRows = null) => {
    setEditingKey('');
    setIsNewItem(false);
    setRefetchData({ spec: [], partType: [], partNumber: [] });
    setExpandedRowKeys(expandeRows);

    form.setFieldsValue({
      spec: [],
      partType: [],
      partNumber: [],
    });
  };

  const formatData = (content) => {
    const dataContent = content.length
      ? content.map((component) => {
          return {
            id: component.id,
            partType: component.partType,
            lastUpdateDateFile: component.lastUpdateDateFile,
            children: component.children.flatMap((child) => {
              return child.children.map((item) => {
                return {
                  ...item,
                  id_spec: child.id,
                  spec: child.spec,
                  lastUpdateDateFile_spec: child.lastUpdateDateFile,
                };
              });
            }),
          };
        })
      : [];

    setData(dataContent);
  };

  const fetchCategories = async () => {
    setIsLoading((prev) => ({ ...prev, category: true }));
    try {
      const content = await api.get(
        getURI('components/filter', {
          filterToReturn: 'category',
          cm: params.cm,
          product: params.product,
        })
      );
      if (content.status === 200) {
        setCategoryOptions(formatDataToSelect(content.data, true));
      }
      if (content.status === 204) {
        setCategoryOptions([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading((prev) => ({ ...prev, category: false }));
    }
  };

  const fetchSpecification = async () => {
    setIsLoading((prev) => ({ ...prev, spec: true }));
    try {
      const content = await api.get(
        getURI('components/filter', {
          filterToReturn: 'spec',
          cm: params.cm,
          product: params.product,
          category: params.partType,
        })
      );
      if (content.status === 200) {
        setSpecOptions(formatDataToSelect(content.data, true));
      }
      if (content.status === 204) {
        setSpecOptions([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading((prev) => ({ ...prev, spec: false }));
    }
  };

  const fetchPartNumber = async () => {
    setIsLoading((prev) => ({ ...prev, partNumber: true }));
    try {
      const content = await api.get(
        getURI('components/filter', {
          filterToReturn: 'partNumber',
          cm: params.cm,
          product: params.product,
          category: params.partType,
          spec: params.spec,
        })
      );
      if (content.status === 200) {
        setPartNumberOptions(formatDataToSelect(content.data, true));
      }
      if (content.status === 204) {
        setPartNumberOptions([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading((prev) => ({ ...prev, partNumber: false }));
    }
  };
  const fetchDescription = async () => {
    setIsLoading((prev) => ({ ...prev, description: true }));
    try {
      const content = await api.get(
        getURI('components/filter', {
          filterToReturn: 'description',
          cm: params.cm,
          product: params.product,
          category: params.partType,
          spec: params.spec,
          partNumber: params.partNumber,
        })
      );
      if (content.status === 200) {
        setdescriptionOptions(formatDataToSelect(content.data, true));
      }
      if (content.status === 204) {
        setdescriptionOptions([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading((prev) => ({ ...prev, description: false }));
    }
  };

  const fetchComponents = async () => {
    setIsLoading((prev) => ({ ...prev, content: true }));

    try {
      const {
        data: { content, totalElements, totalPages },
        status,
      } = await api.get(
        getURI('components/tree', {
          ...params,
          leadTime: params.leadTime.length > 0 ? params.leadTime : null,
        })
      );

      if (status === 204) {
        setData([]);
      } else {
        formatData(content);

        setPageControl({
          pageLabel: params.page + 1,
          totalPages,
          totalElements,
        });

        setIsNewItem(false);
        setEditingKey('');
      }
    } catch (error) {
      console.log('Error on the fetch Components');
    } finally {
      setIsLoading((prev) => ({ ...prev, content: false }));
    }
  };

  const changePage = (page, pageSize) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: page });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (data.length === 0) return;

    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setIsModalOpen(true);
    setEditingKey(record.id);
  };

  const handleSaveItem = (responseData) => {
    const partTypeId = `${responseData.id}$${responseData.partType}`;

    const specId = `${partTypeId}$${responseData.spec}`;

    resetControls([partTypeId, specId]);
  };

  const save = async (record) => {
    setIsLoading((prev) => ({ ...prev, content: true }));
    try {
      await form.validateFields();
      const row = await form.getFieldsValue(true);

      const body = {
        partNumber: row.partNumber,
        cm: row.cm,
        product: row.product,
        partType: row.partType,
        spec: row.spec,
        description: row.description,
        local: row.location === 'FOB' ? '1' : '0',
        subComponent: row.subComponent,
        leadTime: row.leadTime,
      };

      let response;

      if (isNewItem) {
        response = await api.post('components', body);
        if (response.status !== 201) throw Error();
      } else {
        const newRow = {
          ...row,
          partType: row.partTypeId,
          spec: row.specId,
          local: row.location === 'FOB' ? '1' : '0',
        };
        delete newRow.partTypeId;
        delete newRow.specId;

        response = await api.put(`components/${row.id}`, newRow);

        handleSaveItem(response?.data);
        if (response.status !== 201) throw Error();
      }
      await fetchComponents();
      oneAlert('success', t('toast.successOnSave'));
      setIsModalOpen(false);
    } catch (error) {
      oneAlert('error', t('toast.errorOnSave'));
    } finally {
      setIsLoading((prev) => ({ ...prev, content: false }));
    }
  };

  const handleAddItem = () => {
    const newItem = {
      id: data.length + 1,
      spec: [],
      partType: [],
      partNumber: [],
      description: '',
      local: '',
      subComponent: '',
      leadTime: '',
    };

    setIsNewItem(true);
    setIsModalOpen(true);
    form.setFieldsValue({ ...newItem });
  };

  const deleteItem = async (itemId) => {
    setIsLoading((prev) => ({ ...prev, content: true }));
    try {
      if (isNewItem) {
        setIsNewItem(false);
        await fetchComponents();
      } else {
        const response = await api.delete(`components/${itemId}`);
        if (response.status !== 200) throw Error();

        await fetchComponents();
        oneAlert('info', t('toast.successOnDelete'));
      }
    } catch (error) {
      oneAlert('error', t('toast.errorOnDelete'));
    } finally {
      setIsLoading((prev) => ({ ...prev, content: true }));
    }
  };

  const isEditing = (record) => record.id === editingKey;

  const cancel = () => setEditingKey('');

  const debouncedEventHandler = useMemo(
    () =>
      debounce((e) => {
        const value = !e.target.value ? '' : e.target.value.toString().toLowerCase().trim();
        setSearchValue(value);
      }, 300),
    []
  );

  const onValuesChange = (changedValues) => {
    if (changedValues.partType) {
      setRefetchData({
        ...refetchData,
        partType: changedValues.partType,
        spec: [],
        partNumber: [],
      });
      form.setFieldsValue({
        spec: [],
        partNumber: [],
      });
    }
    if (changedValues.spec) {
      setRefetchData({ ...refetchData, spec: changedValues.spec, partNumber: [] });
      form.setFieldsValue({
        partNumber: [],
      });
    }
  };

  const handleHeaderReset = (dataIndex) => {
    const filterParams = { ...params };
    delete filterParams[`${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (field, idx) => {
    if (field?.includes('')) {
      const dataIndex = field.replace('', '');
      handleHeaderReset(dataIndex);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [...params[field]];
        newValue.splice(idx, 1);
      } else {
        newValue = '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleUpdateFilters = (field, value) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [`${field}`]: value,
      };
    });
  };

  const columns = [
    {
      dataIndex: 'partType',
      key: 'partType',
      title: t('pages.components.fields.partType'),
      label: t('pages.components.fields.partType'),

      width: '13.125rem',
    },
    {
      dataIndex: 'cm',
      key: 'cm',
      title: t('pages.components.fields.cm'),
      label: t('pages.components.fields.cm'),
      align: 'center',
      width: '6.8125rem',
      render: (value) => {
        return (
          <Row align="middle" justify="center">
            {value && (
              <Flex gap={8} align="center">
                <img src={cm} alt="ícon" width={10.5} height={9.75} />
                <span style={{ fontWeight: 700 }}>
                  {value.toUpperCase() === 'FOXCONN' ? 'FBR' : 'CEB'}
                </span>
              </Flex>
            )}
          </Row>
        );
      },
    },
    {
      dataIndex: 'spec',
      key: 'spec',
      title: t('pages.components.fields.spec'),
      label: t('pages.components.fields.spec'),
      width: '13.125rem',
    },
    {
      dataIndex: 'partNumber',
      key: 'partNumber',
      title: t('pages.components.fields.partNumber'),
      label: t('pages.components.fields.partNumber'),
      width: '8.75rem',
    },
    {
      dataIndex: 'description',
      key: 'description',
      title: t('pages.components.fields.description'),
      label: t('pages.components.fields.description'),

      limit: 255,
      width: '18rem',
    },
    {
      dataIndex: 'local',
      key: 'local',
      title: t('pages.components.fields.local'),
      label: t('pages.components.fields.local'),

      limit: 255,
      width: '7.5rem',
      align: 'center',
      render: (value, row) => {
        const hasValue = parseInt(value, 10) >= 0;
        const local = isFobOrBr(value);
        return Object.hasOwn(row, 'local') && hasValue ? (
          <Tooltip title={value}>
            <StyledIndicationTag text={local} />
          </Tooltip>
        ) : null;
      },
    },
    {
      dataIndex: 'subComponent',
      key: 'subComponent',
      title: t('pages.components.fields.subComponent'),
      label: t('pages.components.fields.subComponent'),

      width: '9.375rem',
      align: 'center',
      render: (value) => (value === 0 ? '' : value),
    },
    {
      dataIndex: 'leadTime',
      key: 'leadTime',
      title: t('pages.components.fields.leadTime'),
      label: t('pages.components.fields.leadTime'),

      limit: 255,
      align: 'center',
      width: '7.5rem',
      render: (value, row) => {
        if (row.partNumber) {
          const text = row.leadTime !== null ? `${row.leadTime} ${t('common.days')}` : '-';
          return (
            <Row justify="center" style={{ gap: '0.5rem' }}>
              <HistoryOutlined />
              <span>{text}</span>
            </Row>
          );
        }
        return null;
      },
    },
    {
      key: 'action',
      title: t('common.action'),
      width: '8.125rem',
      render: (_, record) => {
        const editable = isEditing(record);

        const actions = [
          {
            key: '1',
            label: record.partNumber ? (
              <Button
                type="link"
                disabled={isNewItem || editingKey !== ''}
                onClick={() => edit(record)}
                data-cy="edit"
              >
                {t('common.edit')}
              </Button>
            ) : null,
          },
          {
            key: '2',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                onConfirm={(e) => deleteItem(record.id)}
                onClick={(e) => e.stopPropagation()}
                onCancel={(e) => e.stopPropagation()}
                placement="topLeft"
              >
                <Button type="link" danger disabled={isNewItem} data-cy="delete">
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button onClick={() => save(record)} type="primary" data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm
              title={t('common.cancelMessage')}
              onConfirm={() => (isNewItem ? deleteItem(record.id) : cancel())}
            >
              <Button shape="circle" default icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction dropdownItems={actions} />
        );
      },
    },
  ];

  const inputNode = (column) => {
    switch (column) {
      case 'partType':
        return (
          <ComponentsTypeSelect
            allowClear={false}
            placeholder={t('filter.partType')}
            intable="intable"
            search={searchValue}
            onKeyUp={debouncedEventHandler}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'local':
        return <LocalSelect intable="intable" onClick={(e) => e.stopPropagation()} />;
      case 'leadTime':
        return <Input maxLength={3} disabled={!isNewItem} />;
      default:
        return <Input onClick={(e) => e.stopPropagation()} />;
    }
  };

  const onCellRecords = (record, col) => ({
    record,
    newInputNode: inputNode(col.dataIndex),
    inputType:
      col.dataIndex === 'subComponent'
        ? 'numberWithZero'
        : col.dataIndex === 'description'
        ? 'stringOnly'
        : col.dataIndex === 'leadTime'
        ? 'number'
        : 'text',
    required: col.required,
    dataIndex: col.dataIndex,
    title: col.title,
    editable: isEditing(record),
    editing: isEditing(record),
  });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => onCellRecords(record, col),
    };
  });

  const exportColumns = columns.filter((col) => col.key !== 'action');

  const transformData = (dataSource) => {
    const rows = [];

    dataSource.forEach((partType) => {
      if (partType?.children?.length) {
        partType.children.forEach((spec) => {
          if (spec?.children?.length) {
            spec.children.forEach((partNumber) => {
              rows.push({
                partType: partType.partType,
                spec: spec.spec,
                partNumber: partNumber.partNumber,
                description: partNumber.description,
                local: partNumber.local,
                subComponent: partNumber.subComponent,
                leadTime: partNumber.leadTime !== null ? partNumber.leadTime : '-',
              });
            });
          }

          //if no partNumber
          if (!spec?.children?.length) {
            rows.push({
              partType: partType.partType,
              spec: spec.spec,
              partNumber: '',
              description: '',
              local: '',
              subComponent: '',
              leadTime: '',
            });
          }
        });
      }

      //if no spec
      if (!partType?.children?.length) {
        rows.push({
          partType: partType.partType,
          spec: '',
          partNumber: '',
          description: '',
          local: '',
          subComponent: '',
          leadTime: '',
        });
      }
    });

    return rows;
  };

  const clearAllFilters = () => {
    setParams({
      ...initialParams,
      cm: params.cm,
    });
  };

  const handleRenderClearAllFilters = () => {
    if (params.partType || params.spec || params.partNumber || params.description || params.local) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const HandleRenderFiltersTags = () => {
    return (
      <>
        {params.partType && (
          <StyledFilterTag
            title={params.partType}
            label={t('pages.components.fields.partType')}
            value={params.partType}
            closeble
            onClose={() => handleCloseFilteredTag('partType')}
          />
        )}
        {params.spec && (
          <StyledFilterTag
            title={params.spec}
            label={t('pages.components.fields.spec')}
            value={params.spec}
            closeble
            onClose={() => handleCloseFilteredTag('spec')}
          />
        )}
        {params.partNumber && (
          <StyledFilterTag
            title={params.partNumber}
            label={t('pages.components.fields.partNumber')}
            value={params.partNumber}
            closeble
            onClose={() => handleCloseFilteredTag('partNumber')}
          />
        )}
        {params.description && (
          <StyledFilterTag
            title={params.description}
            label={t('pages.components.fields.description')}
            value={params.description}
            closeble
            onClose={() => handleCloseFilteredTag('description')}
          />
        )}
        {params.local && (
          <StyledFilterTag
            title={params.local}
            label={t('pages.components.fields.local')}
            value={params.local}
            closeble
            onClose={() => handleCloseFilteredTag('local')}
          />
        )}
      </>
    );
  };
  useEffect(() => {
    if (firstRender) return;
    setParams((prevParams) => {
      return {
        ...prevParams,
        partType: null,
        spec: null,
        partNumber: null,
        description: null,
        local: null,
      };
    });
    Promise.all([fetchCategories(), fetchSpecification(), fetchPartNumber(), fetchDescription()]);
  }, [params.cm]);
  useEffect(() => {
    if (firstRender) return;
    setParams((prevParams) => {
      return {
        ...prevParams,
        partType: null,
        spec: null,
        partNumber: null,
        description: null,
        local: null,
      };
    });
    Promise.all([fetchCategories(), fetchSpecification(), fetchPartNumber(), fetchDescription()]);
  }, [params.product]);

  useEffect(() => {
    if (firstRender) return;
    setParams((prevParams) => {
      return {
        ...prevParams,
        spec: null,
        partNumber: null,
        description: null,
        local: null,
      };
    });
    Promise.all([fetchSpecification(), fetchPartNumber(), fetchDescription()]);
  }, [params.partType]);

  useEffect(() => {
    if (firstRender) return;
    setParams((prevParams) => {
      return {
        ...prevParams,
        partNumber: null,
        description: null,
        local: null,
      };
    });
    Promise.all([fetchPartNumber(), fetchDescription()]);
  }, [params.spec]);

  useEffect(() => {
    if (firstRender) return;
    setParams((prevParams) => {
      return {
        ...prevParams,
        description: null,
        local: null,
      };
    });
    fetchDescription();
  }, [params.partNumber]);

  useEffect(() => {
    Promise.all([
      fetchCategories(),
      fetchSpecification(),
      fetchPartNumber(),
      fetchDescription(),
      fetchComponents(),
    ]);
    setFirstRender(false);
  }, []);

  return {
    data,
    editingKey,
    params,
    pageControl,
    searchValue,
    mergedColumns,
    exportColumns,
    refetchData,
    isLoading,
    form,
    isNewItem,
    setIsNewItem,
    expandedRowKeys,
    categoryOptions,
    specOptions,
    partnumberOptions,
    descriptionOptions,
    sourcOptions,
    cmOptions,
    productOptions,
    sourceOptions,
    debouncedEventHandler,
    transformData,
    onValuesChange,
    fetchComponents,
    changePage,
    handleChangeTable,
    save,
    edit,
    cancel,
    handleAddItem,
    isEditing,
    deleteItem,
    handleCloseFilteredTag,
    getQueryParams,
    setExpandedRowKeys,
    HandleRenderFiltersTags,
    handleRenderClearAllFilters,
    handleUpdateFilters,
    fetchCategories,
    fetchSpecification,
    fetchPartNumber,
    fetchDescription,
    rangeDays,
    setIsModalOpen,
    isModalOpen,
  };
}
