import { Tooltip } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HeaderCard from './HeaderCards';
import * as S from './styles';
import { useFinance } from './useFinance';

import ExpandableCard from '../../components/Common/ExpandableCard';
import SimpleTable from '../../components/Tables/SimpleTable';
import { handleFormatValueFields } from '../../helpers/nUtils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { PricesPageProps } from './types';

export function FinancePage(props: any) {
  const { t } = useTranslation();
  const { search } = useLocation();

  useDocumentTitle(t('pages.finance.title'));

  const {
    openCbomCard,
    cbomItens,
    columns,
    priceProps,
    isLoading,
    setPriceProps,
    setOpenCbomCard,
    handleFetchCbomItens,
    handleFormatQuotePrice,
  } = useFinance();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    const params = {
      level: urlParams.get('level'),
      compType: urlParams.get('compType'),
      sku: urlParams.get('sku'),
      monthYear: urlParams.get('monthYear'),
      category: urlParams.get('category'),
      cm: urlParams.get('cm'),
      source: urlParams.get('source'),
      project: urlParams.get('project'),
      quotePrice: urlParams.get('quotePrice'),
    };

    setPriceProps(params as PricesPageProps);
  }, [search]);

  useEffect(() => {
    priceProps && handleFetchCbomItens();
  }, [priceProps]);

  return (
    <S.Container>
      <S.TitlePage>{`${priceProps?.sku || '-'} | ${priceProps?.project || '-'}`}</S.TitlePage>
      <S.InfoContainer>
        <S.QuotePriceContainer>
          <S.LabelInfoContainer>{t('pages.financePage.labels.quuotesPrice')}</S.LabelInfoContainer>
          <S.QuotesInfoPriceContainer>
            <span>USD</span>
            <Tooltip
              placement="right"
              title={handleFormatValueFields({
                number: priceProps?.quotePrice || '',
                minFractional: 4,
                maxFactional: 4,
              })}
            >
              <p>{handleFormatQuotePrice(priceProps?.quotePrice)}</p>
            </Tooltip>
          </S.QuotesInfoPriceContainer>
        </S.QuotePriceContainer>
        <S.OtherInformationsPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.monthYear')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.monthYear || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.category')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.category || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.cm')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.cm || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.source')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.source || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
        </S.OtherInformationsPriceContainer>
      </S.InfoContainer>

      <ExpandableCard
        isOpen={openCbomCard}
        headerCard={
          <HeaderCard
            cbom={`CBOM ${priceProps?.level}`}
            componentName={priceProps?.category || '-'}
          />
        }
        onExpandableContent={() => {
          setOpenCbomCard(!openCbomCard);
        }}
      >
        <SimpleTable
          striped
          bordered={false}
          pagination={false}
          dataSource={cbomItens}
          rowKey="partNumber"
          columns={columns}
          headerNoPadding
          isLoading={isLoading}
        />
      </ExpandableCard>
    </S.Container>
  );
}
