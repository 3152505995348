import React, { useEffect } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ExpandableItem from '../../../../components/Common/ExpandableItem';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import theme from '../../../../styles/theme';
import { CustomTooltip } from './CustomTooltip';
import { SkuComponents } from './SkuComponents';
import * as S from './styles';
import { SubCardValueView } from './SubCardValueView';
import { SkuAnalysisProps } from './types';
import { useSkuAnalysis } from './useSkuAnalysis';

const SkuAnalysisFunction = ({ skus, date, level, cm, isFirstRendering }: SkuAnalysisProps) => {
  const {
    open,
    skusData,
    params,
    currentDate,
    setCurrentDate,
    nextQuarter,
    previousQuarter,
    toogleView,
    fetchSkuAnalysis,
    getDataForGraph,
    nextMonth,
    previousMonth,
    isLoadingDataGraphic,
  } = useSkuAnalysis();

  useEffect(() => {
    !isFirstRendering && setCurrentDate(date);
  }, [date]);

  useEffect(() => {
    if (!isFirstRendering) {
      fetchSkuAnalysis(skus, date);
    }
  }, [skus, params, date]);
  return (
    <S.Card>
      <S.HeaderExpandableItem>
        <S.TitleContainer>
          <ExpandableItem
            isOpen={open}
            onClick={() => {
              toogleView();
            }}
          />
          <span>SKU Analysis</span>
        </S.TitleContainer>
        <S.ArrowsContainer>
          <S.ArrowLeft
            style={{ color: `${theme.colorsDesignSystem.primary}` }}
            onClick={() => {
              if (params.period === 'month') return previousMonth();
              previousQuarter();
            }}
          />
          <S.ArrowRight
            style={{ color: `${theme.colorsDesignSystem.primary}` }}
            onClick={() => {
              if (params.period === 'month') return nextMonth();
              nextQuarter();
            }}
          />
        </S.ArrowsContainer>
      </S.HeaderExpandableItem>
      {open && (
        <div>
          {isLoadingDataGraphic ? (
            <StyledLoading height={20} />
          ) : (
            <>
              <ResponsiveContainer width="96%" height={200}>
                <BarChart barGap={10} barSize={60} data={getDataForGraph()}>
                  <Tooltip
                    content={
                      <CustomTooltip
                        skus={getDataForGraph()}
                        skusNames={[skus?.[0]?.sku, skus?.[1]?.sku, skus?.[2]?.sku]}
                      />
                    }
                  />
                  <CartesianGrid strokeDasharray="1 1" vertical={false} />
                  {skus?.[0] && <Bar dataKey="sku1Value" fill={skus[0].color} />}
                  {skus?.[1] && <Bar dataKey="sku2Value" fill={skus[1].color} />}
                  {skus?.[2] && <Bar dataKey="sku3Value" fill={skus[2].color} />}
                  <XAxis dataKey="month" />
                  <YAxis dataKey="sku1Value" domain={[0, 1000]} />
                </BarChart>
              </ResponsiveContainer>
              <S.SubCardContainer>
                <S.SubCard>
                  <S.SubCardHeader>
                    Last Month:{' '}
                    <S.BlueText>
                      {params.period !== 'month'
                        ? params.period.split(',')[0].toUpperCase()
                        : skusData?.lastMonthDtos?.[0].month}
                    </S.BlueText>
                  </S.SubCardHeader>
                  <S.SubCardBody>
                    <SubCardValueView
                      skus={skus}
                      skusData={
                        params.period !== 'month'
                          ? skusData?.lastAverageSkuMonths
                          : skusData?.lastMonthDtos
                      }
                    />
                  </S.SubCardBody>
                </S.SubCard>
                <S.SubCard>
                  <S.SubCardHeader>
                    Month:
                    <S.BlueText>
                      {params.period !== 'month'
                        ? params.period.split(',')[1].toUpperCase()
                        : skusData?.currentMonthDtos?.[0].month}
                    </S.BlueText>
                  </S.SubCardHeader>
                  <S.SubCardBody>
                    <SubCardValueView
                      skus={skus}
                      skusData={
                        params.period !== 'month'
                          ? skusData?.currentAverageSkuMonths
                          : skusData?.currentMonthDtos
                      }
                    />
                  </S.SubCardBody>
                </S.SubCard>
                <S.SubCard>
                  <S.SubCardHeader>
                    Next Month:{' '}
                    <S.BlueText>
                      {params.period !== 'month'
                        ? params.period.split(',')[2].toUpperCase()
                        : skusData?.nextMonthDtos?.[0].month}
                    </S.BlueText>
                  </S.SubCardHeader>
                  <S.SubCardBody>
                    <SubCardValueView
                      skus={skus}
                      skusData={
                        params.period !== 'month'
                          ? skusData?.nextAverageSkuMonths
                          : skusData?.nextMonthDtos
                      }
                    />
                  </S.SubCardBody>
                </S.SubCard>
              </S.SubCardContainer>
            </>
          )}
        </div>
      )}
      <SkuComponents
        skus={skus}
        period={currentDate?.format('MMMM/YYYY').toUpperCase()}
        level={level}
        cm={cm}
        isFirstRendering={isFirstRendering}
      />
    </S.Card>
  );
};

export const SkuAnalysis = React.memo(SkuAnalysisFunction);
