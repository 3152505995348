import { Divider, Flex, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { INewComponent } from './types';

export function NewComponent({
  form,
  cmOptions,
  productOptions,
  categoryOptions,
  isFromAvlc,
  disabledFields,
  sourceOptions,
}: INewComponent) {
  const { t } = useTranslation();
  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        {t('pages.components.modal.dataRegistration')}
      </Divider>
      <Form
        name="add-component"
        layout="vertical"
        form={form}
        initialValues={{ cm: 'Foxconn', product: 'Notebook' }}
      >
        <Form.Item
          label={t('pages.components.modal.partNumber')}
          style={{ width: '25%' }}
          name="partNumber"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            size="large"
            disabled={isFromAvlc || disabledFields?.includes('partNumber')}
            placeholder={t('pages.components.modal.partNumberPlaceholder')}
            style={{ color: 'blue', backgroundColor: 'white' }}
          />
        </Form.Item>
        <Form.Item noStyle>
          <Flex gap={16}>
            <Form.Item
              label={t('pages.components.modal.cm')}
              style={{ width: '6.4375rem' }}
              name="cm"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                disabled={isFromAvlc || disabledFields?.includes('cm')}
                placeholder={t('pages.components.modal.cmPlaceholder')}
                options={cmOptions}
              />
            </Form.Item>
            <Form.Item
              label={t('pages.components.modal.product')}
              style={{ width: '16.5625rem' }}
              name="product"
              rules={[
                {
                  required: true,
                  message: `${t('pages.components.modal.product')} ${t(
                    `common.validate.required`
                  )}`,
                },
              ]}
            >
              <Select
                size="large"
                placeholder={t('pages.components.modal.productPlaceholder')}
                options={productOptions}
                disabled={disabledFields?.includes('product')}
              />
            </Form.Item>
            <Form.Item
              label={t('pages.components.modal.category')}
              style={{ width: '16.8125rem' }}
              name="partType"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                disabled={isFromAvlc || disabledFields?.includes('category')}
                options={categoryOptions}
                showSearch
                placeholder={t('pages.components.modal.categoryPlaceholder')}
              />
            </Form.Item>
            <Form.Item
              label={t('pages.components.modal.specification')}
              style={{ width: '16.5625rem' }}
              name="spec"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                size="large"
                disabled={isFromAvlc || disabledFields?.includes('specification')}
                placeholder={t('pages.components.modal.specificationPlaceholder')}
              />
            </Form.Item>
          </Flex>
        </Form.Item>

        <Form.Item noStyle>
          <Flex gap={16}>
            <Form.Item
              label={t('pages.components.modal.description')}
              style={{ width: '33.625rem' }}
              name="description"
            >
              <Input
                size="large"
                disabled={isFromAvlc || disabledFields?.includes('description')}
                placeholder={t('pages.components.modal.descriptionPlaceholder')}
              />
            </Form.Item>
            <Form.Item
              label={t('pages.components.modal.source')}
              style={{ width: '8.25' }}
              name="location"
            >
              <Select
                size="large"
                options={
                  sourceOptions || [
                    { label: 'FOB', value: 'FOB' },
                    { label: 'BR', value: 'BR' },
                  ]
                }
                placeholder={t('pages.components.modal.sourcePlaceholder')}
                disabled={disabledFields?.includes('source')}
              />
            </Form.Item>
            <Form.Item
              label={t('pages.components.modal.subComponents')}
              style={{ width: '8.25' }}
              name="subComponent"
            >
              <Input
                size="large"
                placeholder={t('pages.components.modal.subComponentsPlaceholder')}
                disabled={disabledFields?.includes('subComponent')}
              />
            </Form.Item>
            <Form.Item
              label={t('pages.components.modal.leadTime')}
              style={{ width: '8.25' }}
              name="leadTime"
            >
              <Input
                size="large"
                placeholder={t('pages.components.modal.leadTimePlaceholder')}
                disabled={disabledFields?.includes('leadTime')}
              />
            </Form.Item>
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
}
