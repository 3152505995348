import { Button, Form, Popconfirm, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { oneAlert, renderDate, getErrorMessage } from '../../helpers/utils';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { api } from '../../services/api';
import { useModal } from '../../components/Modal/useModal';
import { useToggle } from '../../hooks/useToggle';
import { Button as TableActionButton } from '../../components/Button';
import { RoutesPath } from '../../routes/routesPath';

const initialParams = {
  name: '',
};

export function useGroups() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isNewItem, setIsNewItem] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [visibleModalGroup, openModalGroup, closeModalGroup] = useModal();
  const [groupEdit, setGroupEdit] = useState(null);
  const [isGroupModalOpen, toggleGroupModal] = useToggle(false);

  const fetchGroups = async () => {
    setIsLoading(true);

    try {
      const { data: dataApi } = await api.get(
        `${import.meta.env.VITE_API_LOGIN_UNICO}${RoutesPath.groups}`
      );
      setData(dataApi);
    } catch (error) {
      console.log('Error on the fetch Groups', error);
    }
    setIsLoading(false);
  };

  const save = async (id) => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const row = await form.getFieldValue();
      const permissions = row.permissions.map((perm) => `${perm}_gpud`).join(';');

      if (isNewItem) {
        const response = await api.post(`${import.meta.env.VITE_API_LOGIN_UNICO}${RoutesPath.groups}`, {
          name: row.name,
          permissions,
        });
        if (response.status !== 201) throw Error();
      } else {
        const response = await api.put(
          `${import.meta.env.VITE_API_LOGIN_UNICO}${RoutesPath.groups}/${row.old_name}`,
          {
            name: row.name,
            permissions,
          }
        );
        if (response.status !== 200) throw Error();
      }

      await fetchGroups();
      setEditingKey('');
      setIsNewItem(false);

      oneAlert('success', t('toast.successOnSave'));
    } catch (error) {
      oneAlert('error', t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  const handleAddItem = () => {
    setIsNewItem(true);
    toggleGroupModal();
  };

  const onCloseModal = () => {
    setIsNewItem(false);
    form.setFieldsValue({});
    form.resetFields();
    toggleGroupModal();
  };

  const edit = (record) => {
    setIsNewItem(false);
    const permissions = (record.permissions ? record.permissions : '')
      .split(';')
      .map((perm) => perm.replace('_gpud', ''));

    form.setFieldsValue({ ...record, old_name: record.name, permissions });
    toggleGroupModal();
    // setEditingKey(record.id);
  };

  const deleteItem = async (itemId) => {
    setIsLoading(true);
    try {
      if (isNewItem) {
        setIsNewItem(false);
        await fetchGroups();
      } else {
        const response = await api.delete(
          `${import.meta.env.VITE_API_LOGIN_UNICO}${RoutesPath.groups}/${itemId}`
        );
        if (response.status !== 200) throw Error();

        await fetchGroups();
        oneAlert('info', t('toast.successOnDelete'));
      }
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDeleteGroup'));
    }
    setIsLoading(false);
  };

  const isEditing = (record) => {
    return record.id === editingKey;
  };

  const cancel = () => {
    if (isNewItem) {
      const newData = data.filter((item) => item.id !== editingKey);
      setData(newData);
      setIsNewItem(false);
    }
    setEditingKey('');
  };

  const searchFilter = async (newFilters) => {
    setParams({
      ...initialParams,
      ...newFilters,
    });
  };

  const clearFilter = async () => {
    setParams({
      ...initialParams,
      name: null,
    });
  };

  const handleCloseFilteredTag = (field, idx) => {
    let newValue;

    if (Array.isArray(params[field])) {
      newValue = [...params[field]];
      newValue.splice(idx, 1);
    } else {
      newValue = field === 'group' ? null : '';
    }

    setParams({
      ...params,
      [field]: newValue,
    });
  };

  const columns = [
    {
      title: t('pages.groups.fields.name'),
      label: t('pages.groups.fields.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      editable: true,
    },
    {
      title: t('pages.groups.fields.created'),
      label: t('pages.groups.fields.created'),
      dataIndex: 'createdAt',
      key: 'updatedAt',
      sorter: false,
      editable: true,
      render: (value) => renderDate(value, '2-digit'),
    },
    {
      title: t('pages.groups.fields.updated'),
      label: t('pages.groups.fields.updated'),
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      sorter: false,
      editable: true,
      render: (value) => renderDate(value, '2-digit'),
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      width: 130,
      render: (_, record) => {
        const editable = isEditing(record);

        const actions = [
          {
            key: '1',
            label: (
              <TableActionButton
                type="table_action"
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                data-cy="edit"
              >
                {t('common.edit')}
              </TableActionButton>
            ),
          },
          {
            key: '2',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                onConfirm={() => deleteItem(record.name)}
                placement="topLeft"
              >
                <Button type="link" danger disabled={editingKey !== ''} data-cy="delete">
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button type="primary" onClick={save} data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm title={t('common.cancelMessage')} onConfirm={cancel}>
              <Button shape="circle" default icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction dropdownItems={actions} />
        );
      },
    },
  ];

  const mergedColumns = columnsWithAction.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        required: true,
        dataIndex: col.dataIndex,
        title: col.title,
        editable: col.editable && isEditing(record),
        editing: col.dataIndex === 'name' ? isEditing(record) : false,
        limit: null,
      }),
    };
  });

  return {
    data,
    params,
    fetchGroups,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    columns,
    mergedColumns,
    visibleModalGroup,
    openModalGroup,
    closeModalGroup,
    groupEdit,
    isGroupModalOpen,
    toggleGroupModal,
    save,
    onCloseModal,
  };
}
